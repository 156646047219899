<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Área do revendedor</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

		<!-- perfil area start -->
      <section class="perfil__area pt-20 pb-200 grey-bg">
         <div class="container mb-200">
            <div class="row align-items-start">
               <!-- Nav -->
               <div class="nav flex-sm-column nav-pills pe-10 pe-sm-4 mx-auto mb-20 mb-sm-0 justify-content-center" id="perfil-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="carrinho-tab" data-bs-toggle="pill" data-bs-target="#carrinho" aria-controls="carrinho"
                     type="button" role="tab" aria-selected="true" @click="resetCampos">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-shopping-cart me-xl-3"></i><span class="d-none d-xl-block">Meu carrinho</span>
                     </span>
                  </button>
                  <button v-if="dadosUsuario.cliente.permissoes.includes('Visualizar pedidos')" id="pedidos-tab" data-bs-toggle="pill" data-bs-target="#pedidos" aria-controls="pedidos"
                     class="nav-link" type="button" role="tab" aria-selected="false" @click="resetCampos">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-clipboard me-xl-3"></i>
                        <span class="d-block">
                           <span class="d-none d-xl-block">Histórico</span> <span class="d-none d-xl-block">de pedidos</span>
                        </span>
                     </span>
                  </button>
                  <button v-if="dadosUsuario.cliente.permissoes.includes('Visualizar solicitações')" id="solicitacoes-tab" data-bs-toggle="pill" data-bs-target="#solicitacoes" aria-controls="solicitacoes"
                     class="nav-link" type="button" role="tab" aria-selected="false" @click="resetCampos">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-comment-alt-lines me-xl-3"></i><span class="d-none d-xl-block">Solicitações</span>
                     </span>
                  </button>
                  <button v-if="dadosUsuario.cliente.admin" id="perfis-tab" data-bs-toggle="pill" data-bs-target="#perfis" aria-controls="perfis"
                     class="nav-link" type="button" role="tab" aria-selected="false" @click="resetCampos">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-address-card me-xl-3"></i><span class="d-none d-xl-block">Perfis</span>
                     </span>
                  </button>
                  <button v-if="dadosUsuario.cliente.permissoes.includes('Alterar dados')" id="home-tab" data-bs-toggle="pill" data-bs-target="#home" aria-controls="home"
                     class="nav-link" type="button" role="tab" aria-selected="false">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-user me-xl-3"></i><span class="d-none d-xl-block">Meus dados</span>
                     </span>
                  </button>
                  <button class="nav-link" id="notas-tab" data-bs-toggle="pill" data-bs-target="#notas" aria-controls="notas" type="button" role="tab" aria-selected="false">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-file me-xl-3"></i><span class="d-none d-xl-block">Notas fiscais</span>
                     </span>
                  </button>
                  <button class="nav-link" id="boletos-tab" data-bs-toggle="pill" data-bs-target="#boletos" aria-controls="boletos" type="button" role="tab" aria-selected="false">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-barcode me-xl-3"></i><span class="d-none d-xl-block">Boletos</span>
                     </span>
                  </button>
                  <button class="nav-link" id="senha-tab" data-bs-toggle="pill" data-bs-target="#senha" aria-controls="senha" type="button" role="tab" aria-selected="false">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start">
                        <i class="fal fa-lock me-xl-3"></i><span class="d-none d-xl-block">Trocar senha</span>
                     </span>
                  </button>
                  <button class="nav-link" type="button" role="tab" @click="deslogar">
                     <span class="d-flex align-items-center text-center text-xl-start justify-content-center justify-content-xl-start text-danger">
                        <i class="fal fa-sign-out-alt me-xl-3"></i><span class="d-none d-xl-block">Deslogar</span>
                     </span>
                  </button>
               </div>

               <!-- Corpo -->
               <div class="tab-content ps-10 ps-sm-4" id="perfil-tabContent">
                  <!-- Carrinho -->
                  <div class="tab-pane fade show active" id="carrinho" role="tabpanel" aria-labelledby="carrinho-tab">
                     <div class="row" v-if="pedidoFechado.toggle != true">
                        <div class="col-12">
                           <div class="section__head mb-10">
                              <div class="w-100 section__title">
                                 <h3 class="text-center font-16 mb-1">Meu carrinho</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12">
                           <ul>
                              <li class="text-center py-5" v-if="carrinho.itens.length == 0">
                                 <span class="d-block font-14 mb-40">Carrinho vazio</span>
                                 <autocomplete ref="pesquisaCarrinho" :search="searchProdutos" :debounce-time="1000" :get-result-value="formatLabel" @submit="addCarrinho" class="autocomplete-sm wpx-300 mx-auto" placeholder="Adicionar produto"></autocomplete>
                              </li>
                              <li class="mb-15" v-else>
                                 <div class="row align-items-center">
                                    <div class="col d-none d-md-inline">
                                       <autocomplete ref="pesquisaCarrinho" :search="searchProdutos" :debounce-time="1000" :get-result-value="formatLabel" @submit="addCarrinho" class="autocomplete-sm" placeholder="Adicionar produto"></autocomplete>
                                    </div>
                                    <div class="col text-md-end">
                                       <h6 class="font-16 mb-0">Total: 
                                          <span class="cart__sub-total font-16 ms-1">R${{ parseFloat(carrinho.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                                       </h6>
                                    </div>
                                    <div class="w-max-content align-self-center" v-if="dadosUsuario.cliente.permissoes.includes('Criar pedidos')">
                                       <a href="javascript:;" class="t-y-btn" @click="fecharPedido">Fechar pedido</a>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-15" v-if="carrinho.itens.length > 0">
                                 <div class="row card-title">
                                    <div class="wpx-75 d-none d-lg-block"></div>
                                    <div class="col text-start"><i class="far fa-hashtag font-11 me-1"></i> Nome</div>
                                    <div class="wpx-125"><i class="far fa-box font-10 me-1"></i> QTD.</div>
                                    <div class="wpx-125 d-none d-lg-block"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> Unitário</div>
                                    <div class="wpx-125 d-none d-xxl-block"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> ICMS ST</div>
                                    <div class="wpx-125 d-none d-xxl-block"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> IPI</div>
                                    <div class="wpx-125 d-sm-none d-md-block"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> Total</div>
                                    <div class="wpx-75"></div>
                                 </div>
                              </li>

                              <produto v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" :view="'Carrinho'" />
                           </ul>
                        </div>
                     </div>

                     <div class="container-sucesso" v-else>
                        <h4>Obrigado!</h4>
                        <span class="font-16 mb-30">Seu pedido:</span>
                        <p>{{ pedidoFechado.idPedido }} - {{ pedidoFechado.data == null ? new Date() : pedidoFechado.data.dayOfMonth.toString().padStart(2, '0') +'/'+ pedidoFechado.data.monthValue.toString().padStart(2, '0') +'/'+ pedidoFechado.data.year }}</p>
                        <span v-html="sourcePerfil.pedidoSucesso"></span>
                        <span class="text-start mt-50">Atenciosamente,</span>
                        <span class="text-start">Equipe CCM do Brasil</span>
                     </div>
                  </div>

                  <!-- Pedidos -->
                  <div v-if="dadosUsuario.cliente.permissoes.includes('Visualizar pedidos')" class="tab-pane fade" id="pedidos" role="tabpanel" aria-labelledby="pedidos-tab">
                     <div class="row">
                        <div class="col-12">
                           <div class="section__head" :class="pedidoFechado.toggle != true ? 'mb-30' : 'mb-10'">
                              <div class="w-100 section__title">
                                 <h3 class="text-center font-16 mb-1">Histórico de pedidos</h3>
                              </div>
                           </div>
                        </div>
                        <div class="col-12" v-if="pedidoFechado.toggle != true">
                           <ul>
                              <li class="text-center py-5" v-if="usuario.ultimosPedidos.length == 0">Nenhum pedido encontrado</li>
                              <li class="mb-15 d-none d-xxl-block" v-else>
                                 <div class="row card-title justify-content-center align-items-center px-12">
                                    <div class="col px-1"><i class="far fa-hashtag font-11 me-1"></i> Pedido</div>
                                    <div class="wpx-125 px-1"><i class="far fa-calendar font-11 me-1"></i> Data</div>
                                    <div class="wpx-100 px-1"><i class="far fa-file font-10 me-1"></i> Nº nota</div>
                                    <div class="wpx-135 px-1"><span class="limitador"><i class="far fa-calendar font-11 me-1"></i>Data emissão NF</span></div>
                                    <div class="col px-1"><i class="far fa-exchange-alt font-11 me-1"></i> Status</div>
                                    <div class="wpx-135 px-1"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> Total</div>
                                    <div class="wpx-100 px-1"><i class="far fa-desktop font-10 me-1"></i> Origem</div>
                                    <div class="wpx-100 px-1"></div>
                                    <div class="wpx-125 px-1"></div>
                                 </div>
                              </li>

                              <pedido v-for="(pedido, index) in usuario.ultimosPedidos" :key="index" :pedido="pedido" :index="index" @visualizar="verPedido($event)" />
                           </ul>
                        </div>

                        <div class="col-12" v-else>
                           <ul>
                              <li class="text-center py-5" v-if="pedidoFechado.itens.length == 0">Nenhum produto encontrado</li>
                              <li class="mb-15" v-else>
                                 <div class="row align-items-center">
                                    <div class="col d-none d-md-inline">
                                       <h6 class="font-16 mb-0">Pedido '{{ pedidoFechado.id != null ? pedidoFechado.id + (pedidoFechado.idPedido != null ? ' ('+ pedidoFechado.idPedido +')' : '') : pedidoFechado.idPedido != null ? pedidoFechado.idPedido : '' }}'</h6>
                                    </div>
                                    <div class="col text-md-end">
                                       <h6 class="font-16 mb-0">Total: 
                                          <span class="cart__sub-total font-16 ms-1">R${{ parseFloat(pedidoFechado.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                       </h6>
                                    </div>
                                    <div class="w-max-content align-self-center">
                                       <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCampos">Voltar</a>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-15" v-if="pedidoFechado.itens.length > 0">
                                 <div class="row card-title">
                                    <div class="wpx-75 d-none d-lg-block"></div>
                                    <div class="col text-start">Nome</div>
                                    <div class="wpx-125">QTD.</div>
                                    <div class="wpx-125 d-none d-lg-block">Unitário</div>
                                    <div class="wpx-125 d-none d-xxl-block">ICMS ST</div>
                                    <div class="wpx-125 d-none d-xxl-block">IPI</div>
                                    <div class="wpx-125 d-sm-none d-md-block">Total</div>
                                 </div>
                              </li>

                              <produto v-for="(produto, index) in pedidoFechado.itens" :key="index" :produto="produto" :index="index" :view="'Pedidos'" />
                           </ul>
                        </div>
                     </div>
                  </div>

                  <!-- Solicitacoes -->
                  <div v-if="dadosUsuario.cliente.permissoes.includes('Visualizar solicitações')" class="tab-pane fade" id="solicitacoes" role="tabpanel" aria-labelledby="solicitacoes-tab">
                     <tabSolicitacoes />
                  </div>

                  <!-- Perfis -->
                  <div v-if="dadosUsuario.cliente.admin" class="tab-pane fade" id="perfis" role="tabpanel" aria-labelledby="perfis-tab">
                     <div class="row">
                        <div class="col-12">
                           <div class="section__head mb-20">
                              <div class="row section__title mb-0 align-items-end">
                                 <div class="col"></div>
                                 <div class="col pb-2">
                                    <h3 class="text-center font-16 mb-1">Perfis</h3>
                                 </div>
                                 <div class="col text-end">
                                    <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="togglePerfil(null)">
                                       <i class="far font-12" :class="perfil.toggle ? 'fa-undo' : 'fa-plus'"></i>
                                       <span class="d-none d-lg-inline ms-1"> {{ perfil.toggle ? 'Voltar' : 'Novo perfil' }}</span>
                                    </a>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="col-12" v-if="perfil.toggle != true">
                           <ul>
                              <li class="mb-15" v-if="usuario.perfis.length > 0">
                                 <div class="row card-title">
                                    <div class="col px-1"><i class="far fa-user font-10 me-1"></i> Nome do perfil</div>
                                    <div class="col px-1"><i class="far fa-envelope font-10 me-1"></i> Email de acesso</div>
                                    <div class="wpx-200 px-1 d-none d-sm-block"></div>
                                 </div>
                              </li>

                              <perfil v-for="(perfil, index) in usuario.perfis" :key="index" :perfil="perfil" @editar="togglePerfil($event)" />
                              <li class="text-center py-5" v-if="usuario.perfis.length == 0">Nenhum perfil encontrado</li>
                           </ul>
                        </div>

                        <div class="col-12" v-else>
                           <div class="row custom-form">
                              <div class="col-sm-6">
                                 <div class="card bg-transparent mb-2">
                                    <div class="card-body p-3">
                                       <div class="row">
                                          <div class="col-12 mb-15">
                                             <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Permissões</h6>
                                          </div>
                                          <permissao v-for="(permissao, index) in perfil.permissoes" :key="index" :permissao="permissao" :index="index" />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-sm-6">
                                 <div class="card bg-transparent">
                                    <div class="card-body p-3">
                                       <div class="row">
                                          <div class="col-12 mb-2">
                                             <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Informações básicas</h6>
                                          </div>
                                          <div class="col-12 mb-15">
                                             <label><i class="far fa-user font-12 me-1"></i> Nome</label>
                                             <input type="text" class="form-control" v-model="perfil.nome" />
                                          </div>
                                          <div class="col-12 mb-15">
                                             <label><i class="far fa-envelope font-12 me-1"></i> Email para login</label>
                                             <input type="text" class="form-control" v-model="perfil.email" />
                                          </div>
                                          <div class="col-12 mb-15" v-if="perfil.id != null">
                                             <label><i class="far fa-lock font-12 me-1"></i> Senha atual</label>
                                             <input type="text" class="form-control key" v-model="perfil.senha.atual" />
                                          </div>
                                          <div class="col-12 mb-15">
                                             <label><i class="far fa-lock-alt font-12 me-1"></i> Senha nova</label>
                                             <input type="text" class="form-control key" v-model="perfil.senha.nova" />
                                          </div>
                                          <div class="col-12 text-end">
                                             <button class="t-y-btn t-y-btn-2" @click="savePerfil">Salvar <i class="far fa-check font-12 ms-2"></i></button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <!-- Meus dados -->
                  <div v-if="dadosUsuario.cliente.permissoes.includes('Alterar dados')" class="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                     <div class="section__head mb-20">
                        <div class="w-100 section__title">
                           <h3 class="text-center font-16 mb-1">Meus dados</h3>
                        </div>
                     </div>
                     <div class="card bg-transparent">
                        <div class="card-body p-3">
                           <div class="row custom-form">
                              <div class="col-12 mb-10">
                                 <label><i class="far fa-user font-12 me-1"></i> Nome</label>
                                 <input type="text" class="form-control" v-model="usuario.nome" />
                              </div>
                              <div class="col-12 mb-10">
                                 <label><i class="far fa-envelope font-12 me-1"></i> Email</label>
                                 <input type="text" class="form-control" v-model="usuario.email" />
                              </div>
                              <div class="col-12 mb-10">
                                 <label><i class="far fa-phone-alt font-12 me-1"></i> Telefone</label>
                                 <input type="text" class="form-control" v-model="usuario.telefone" />
                              </div>
                              <div class="col-12 text-end">
                                 <button class="t-y-btn t-y-btn-2" @click="saveUsuario">Salvar <i class="far fa-check font-12 ms-2"></i></button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <!-- Notas -->
                  <div class="tab-pane fade" id="notas" role="tabpanel" aria-labelledby="notas-tab">
                     <div class="row custom-form">
                        <div class="col-12">
                           <div class="section__head mb-20">
                              <div class="w-100 section__title">
                                 <h3 class="text-center font-16 mb-1">Notas fiscais</h3>
                              </div>
                           </div>
                        </div>

                        <div class="col-12" v-if="nota.toggle != true">
                           <ul>
                              <li class="text-center py-5" v-if="usuario.notas.length == 0">Nenhuma nota encontrada</li>
                              <li class="mb-15 d-none d-xxl-block" v-else>
                                 <div class="row card-title justify-content-center align-items-center px-12">
                                    <div class="col px-1"><i class="far fa-barcode font-11 me-1"></i>Chave</div>
                                    <div class="wpx-200 px-1"><i class="far fa-truck font-11 me-1"></i>Transportadora</div>
                                    <div class="wpx-125 px-1"><i class="far fa-hashtag font-11 me-1"></i>Número</div>
                                    <div class="wpx-125 px-1"><i class="far fa-calendar font-11 me-1"></i>Data emissão</div>
                                    <div class="wpx-150 px-1"><i class="far fa-money-bill-wave-alt font-11 me-1"></i>Valor</div>
                                    <div class="wpx-285 px-1"></div>
                                 </div>
                              </li>

                              <nota v-for="(nota, index) in usuario.notas" :key="index" :nota="nota" :index="index" :view="'Perfil'" @visualizar="verNota($event)" />
                           </ul>
                        </div>

                        <div class="col-12" v-else>
                           <ul>
                              <li class="text-center py-5" v-if="nota.itens.length == 0">Nenhum produto encontrado</li>
                              <li class="mb-15" v-else>
                                 <div class="row align-items-center">
                                    <div class="col d-none d-md-inline">
                                       <h6 class="font-16 mb-0">Nota '{{ nota.numero }}'</h6>
                                    </div>
                                    <div class="col text-md-end">
                                       <h6 class="font-16 mb-0">Total: 
                                          <span class="cart__sub-total font-16 ms-1">R${{ parseFloat(nota.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                                       </h6>
                                    </div>
                                    <div class="w-max-content align-self-center">
                                       <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="resetCampos">Voltar</a>
                                    </div>
                                 </div>
                              </li>
                              <li class="mb-15" v-if="nota.itens.length > 0">
                                 <div class="row card-title">
                                    <div class="wpx-75 d-none d-lg-block"></div>
                                    <div class="col text-start">Nome</div>
                                    <div class="wpx-125">QTD.</div>
                                    <div class="wpx-125 d-none d-lg-block">Unitário</div>
                                    <div class="wpx-125 d-none d-xxl-block">ICMS ST</div>
                                    <div class="wpx-125 d-none d-xxl-block">IPI</div>
                                    <div class="wpx-125 d-sm-none d-md-block">Total</div>
                                 </div>
                              </li>

                              <produto v-for="(produto, index) in nota.itens" :key="index" :produto="produto" :index="index" :view="'Pedidos'" />
                           </ul>
                        </div>
                     </div>
                  </div>

                  <!-- Boletos -->
                  <div class="tab-pane fade" id="boletos" role="tabpanel" aria-labelledby="boletos-tab">
                     <div class="row custom-form">
                        <div class="col-12">
                           <div class="section__head mb-20">
                              <div class="w-100 section__title">
                                 <h3 class="text-center font-16 mb-1">Boletos</h3>
                              </div>
                           </div>
                        </div>

                        <div class="col-12">
                           <ul>
                              <li class="text-center py-5" v-if="usuario.boletos.length == 0">Nenhum boleto encontrado</li>
                              <li class="mb-15 d-none d-xxl-block" v-else>
                                 <div class="row card-title justify-content-center align-items-center px-12">
                                    <div class="wpx-125 px-1"><i class="far fa-calendar font-11 me-1"></i>Vencimento</div>
                                    <div class="wpx-125 px-1"><i class="far fa-hashtag font-11 me-1"></i> Fatura</div>
                                    <div class="wpx-125 px-1"><i class="far fa-copy font-10 me-1"></i> Parcela</div>
                                    <div class="wpx-125 px-1"><i class="far fa-info-circle font-10 me-1"></i> Origem</div>
                                    <div class="wpx-135 px-1"><i class="far fa-money-bill-wave-alt font-10 me-1"></i> Valor</div>
                                    <div class="col px-1"><i class="far fa-barcode font-10 me-1"></i> Nosso número</div>
                                    <div class="wpx-125 px-1"><i class="far fa-calendar font-11 me-1"></i> Data emissão</div>
                                    <div class="wpx-125 px-1"></div>
                                 </div>
                              </li>

                              <boleto v-for="(boleto, index) in usuario.boletos" :key="index" :boleto="boleto" :index="index" />
                           </ul>
                        </div>
                     </div>
                  </div>
                  
                  <!-- Senha -->
                  <div class="tab-pane fade" id="senha" role="tabpanel" aria-labelledby="senha-tab">
                     <div class="section__head mb-20">
                        <div class="w-100 section__title">
                           <h3 class="text-center font-16 mb-1">Trocar senha</h3>
                        </div>
                     </div>
                     <div class="card bg-transparent">
                        <div class="card-body p-3">
                           <div class="row custom-form">
                              <div class="col-12 mb-10">
                                 <label><i class="far fa-lock font-12 me-1"></i> Senha atual</label>
                                 <input type="text" class="form-control key" v-model="senha.atual" />
                              </div>
                              <div class="col-12 mb-10">
                                 <label><i class="far fa-lock-alt font-12 me-1"></i> Senha nova</label>
                                 <input type="text" class="form-control key" v-model="senha.nova" />
                              </div>
                              <div class="col-12 text-end">
                                 <button class="t-y-btn t-y-btn-2" @click="alterarSenha">Salvar <i class="far fa-check font-12 ms-2"></i></button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <!-- Promocional -->
      <div class="modal fade" id="modalPromocional" tabindex="-1" role="dialog" aria-hidden="true">
         <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-body p-0 overflow-hidden">
                  <button class="btn btn-close-modal" data-bs-dismiss="modal" aria-label="Close"><i class="far fa-times"></i></button>
                  <div class="ratio ratio-4x3">
                     <img title="Foto" :src="clienteData.imagepopup" />
                  </div>
               </div>
            </div>
         </div> 
      </div>
   </main>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import nota from '@/components/perfil/Nota.vue'
import boleto from '@/components/perfil/Boleto.vue'
import perfil from '@/components/perfil/Perfil.vue'
import pedido from '@/components/perfil/Pedido.vue'
import produto from '@/components/perfil/Produto.vue'
import permissao from '@/components/perfil/Permissao.vue'
import tabSolicitacoes from '@/views/perfil/TabSolicitacoes.vue'

export default {
	name: 'Perfil',
   data: function () {
		return {
			usuario: {'telefone': '', 'nome': '', 'id': null, 'cnpj': '', 'email': '', 'ultimosPedidos': [], 'perfis': [], 'permissoes': [], 'notas': [], 'boletos': []},
         senha: {'atual': '', 'nova': ''},
         pedidoFechado: {'toggle': false, 'id': null, 'idPedido': null, 'status': 'Pendente', 'valor': 0, 'idCliente': '000001', 'data': {}, 'itens': []},
         nota: {'toggle': false, 'transportador': null, 'numero': null, 'statusEntrega': null, 'valor': null, 'chave': null, 'id': null, 'dataEmissao': null, 'dataAtualizacaoTransportador': null, 'itens': []},
         perfil: {'toggle': false, 'id': null, 'codigo': null, 'nome': '', 'email': '', 'permissoes': [], 'senha': {'atual': '', 'nova': ''}},
         pesquisaProduto: ''
		}
	},
   computed: {
		...mapState({
         sourcePerfil: state => state.source.perfil,
			dadosUsuario: state => state.dadosUsuario,
         clienteData: state => state.clienteData,
			carrinho: state => state.carrinho,
			urlRest: state => state.urlRest
		})
	},
   components: {
      produto, pedido, tabSolicitacoes, perfil, permissao, boleto, nota
   },
   methods: {
      deslogar : function () {
			this.$store.dispatch('deslogar')
		},
      resetCampos : function () {
         Object.assign(this.$data.pedidoFechado, this.$options.data.apply(this).pedidoFechado)
         Object.assign(this.$data.perfil, this.$options.data.apply(this).perfil)
         Object.assign(this.$data.nota, this.$options.data.apply(this).nota)
      },
      formatLabel : function (result) {
         return 'Cód. '+ result.codigo +' - R$ '+ parseFloat(result.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) +' - '+ result.nome
      },
      addCarrinho : function (result) {
         this.$store.dispatch('addCarrinho', {'produto': JSON.parse(JSON.stringify(result)), 'selectVariaco': true })
         setTimeout(() => this.$refs.pesquisaCarrinho.$refs.input.value = '', 50);
      },
      searchProdutos : function (input) {
         if (input.length < 3) { return [] }

         return this.$axios({
				method: 'get',
				url: this.urlRest +'site/searchProdutosLabel',
            params: {
               valor: input,
               pecas: true
            }
			}).then(response => {
            return response.data.filter(p => p.preco != null && parseFloat(p.preco) > 0)
			})
      },
      buscarUsuario : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/getUsuario',
            params: {
               id: this.dadosUsuario.cliente.id
            }
         }).then((response) => {
            this.usuario = response.data

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      togglePerfil : function (perfil) {
         if (perfil == null) {
            this.perfil = {'toggle': !this.perfil.toggle, 'id': null, 'codigo': null, 'nome': '', 'email': '', 'permissoes': this.usuario.permissoes, 'senha': {'atual': '', 'nova': ''}}
         } else {
            this.perfil = {
               'toggle': true, 'id': perfil.id, 'codigo': perfil.codigo, 'nome': perfil.nome, 'email': perfil.email, 'permissoes': perfil.permissoes,
               'senha': {'atual': '', 'nova': ''}
            }
         }
      },
      savePerfil : function () {
         if (this.perfil.email == null || String(this.perfil.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         } else if ((this.perfil.id == null || (this.perfil.senha.atual != null && String(this.perfil.senha.atual).trim().length > 0)) && 
            this.perfil.senha.nova != null && String(this.perfil.senha.nova).trim().length > 0 && String(this.perfil.senha.nova).trim().length < 4) {
            this.$toast.fire({
               icon: 'error',
               title: 'Senha menor que 4 caracteres!'
            });

            return
         }

         if (this.perfil.nome == null || String(this.perfil.nome).trim().length == 0) {
            this.perfil.nome = 'Perfil '+ (this.usuario.perfis.length + 1)
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuario/savePerfil',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.perfil

         }).then((response) => {
            this.usuario.perfis = response.data;
            this.perfil.toggle = false

            this.$toast.fire({
               icon: 'success',
               title: 'Perfil salvo!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
               } else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Senha incorreta!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      saveUsuario : function () {
         if (this.usuario.telefone == null || String(this.usuario.telefone).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Telefone inválido!'
            });

            return
         } else if (this.usuario.nome == null || String(this.usuario.nome).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Nome inválido!'
            });

            return
         } else if (this.usuario.email == null || String(this.usuario.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuario/saveUsuario',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.usuario

         }).then(() => {
            this.$toast.fire({
               icon: 'success',
               title: 'Alterações salvas!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      fecharPedido : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Fechar pedido',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'site/finalizarPedido',
                  headers: {
                     'Content-Type': 'application/json'
                  },
                  data: this.carrinho.itens

               }).then((response) => {
                  this.$store.dispatch('limparCarrinho')

                  this.usuario.ultimosPedidos.unshift(response.data)
                  this.pedidoFechado = {
                     'toggle': true,
                     'id': response.data.id,
                     'idPedido': response.data.idPedido,
                     'status': response.data.status,
                     'valor': response.data.valor,
                     'idCliente': response.data.idCliente,
                     'data': response.data.data,
                     'itens': []
                  }
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      },
      verPedido : function (pedido) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'site/getPedidoProdutos',
            params: {
               idSequencial: pedido.idSequencial,
               origem: pedido.origem
            }
         }).then((response) => {
            this.pedidoFechado = {
               'toggle': true,
               'id': pedido.id,
               'idPedido': pedido.idPedido,
               'status': pedido.status,
               'valor': pedido.valor,
               'idCliente': pedido.idCliente,
               'data': pedido.dataEmissao,
               'itens': response.data
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      verNota : function (nota) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/getItensNota',
            params: {
               id: nota.id
            }
         }).then((response) => {
            this.nota = {
               'toggle': true,
               'transportador': nota.transportador,
               'numero': nota.numero,
               'statusEntrega': nota.statusEntrega,
               'valor': nota.valor,
               'chave': nota.chave,
               'id': nota.id,
               'dataEmissao': nota.dataEmissao,
               'dataAtualizacaoTransportador': nota.dataAtualizacaoTransportador,
               'itens': response.data
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      alterarSenha : function () {
         if (this.senha.nova.trim().length < 4) {
            this.$toast.fire({
               icon: 'error',
               title: 'Senha menor que 4 caracteres!'
            })

            return
         }

         Swal.fire({
            icon: 'warning',
            title: 'Alterar senha',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'login/trocarSenha',
                  headers: {
                     'Content-Type': 'application/json'
                  },
                  data: this.senha

               }).then(() => {
                  this.senha = {'atual': '', 'nova': ''}
                  this.$toast.fire({
                     icon: 'success',
                     title: 'Senha alterada!'
                  });
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else if (error.response.status == 400) {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Senha incorreta!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('toggleCarrinho', false)
      this.buscarUsuario()

      if (this.clienteData.imagepopup != null && String(this.clienteData.imagepopup).trim().length > 0) {
         $('#modalPromocional').modal('show')
      }
   }
}

</script>

<style scoped>

#perfil-tab .nav-link i {
   font-size: 20px;
   width: 21px;
}

#perfil-tab .nav-link i.fa-sign-out-alt {
   transform: translateX(-2px);
}

#perfil-tab .nav-link i.fa-shopping-cart {
   transform: translateX(-3px);
}

#perfil-tab .nav-link {
   line-height: 1;
   padding: 15px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12.5px;
   transition: none !important;
}

#perfil-tab .nav-link * {
   transition: none !important;
}

#perfil-tab,
#perfil-tabContent {
   width: 100%;
}

@media (min-width: 576px) {
   #perfil-tabContent {
      border-left: 1px solid #ebebeb;
      min-height: 210px;
      width: calc(100% - 85px)
   }
   
   #perfil-tab {
      width: 85px;
   }
}

@media (min-width: 1200px) {
   #perfil-tabContent {
      border-left: 1px solid #ebebeb;
      min-height: 210px;
      width: calc(100% - 200px)
   }

   #perfil-tab {
      width: 200px;
   }
}

#perfil-tabContent .card-title {
   padding-bottom: 4px;
}

#perfil-tabContent .container-sucesso {
   width: 800px;
   background: #fff;
   border: 1px solid #ebebeb;
   border-radius: 4px;
   text-align: center;
	padding: 50px 75px;
   margin: 0 auto;
}

#perfil-tabContent .container-sucesso h4 {
   font-size: 22px;
   color: #14397f;
   font-weight: 600;
   margin-bottom: 20px;
}

#perfil-tabContent .container-sucesso p {
   font-size: 16px;
   color: #14397f;
   font-weight: 500;
   margin-bottom: 25px;
}

#perfil-tabContent .container-sucesso span {
   display: block;
   font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	#perfil-tabContent .container-sucesso {
		padding: 35px 50px;
      width: 600px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	#perfil-tabContent .container-sucesso {
		padding: 30px 40px;
      width: 500px;
	}
}
@media (max-width: 767px) {
	#perfil-tabContent .container-sucesso {
		padding: 30px;
      width: 320px;
	}
}

/* Modal */
#modalPromocional .modal-content {
   background-color: #0000 !important;
   border-color: #0000 !important;
}

#modalPromocional img {
   object-fit: cover;
}

#modalPromocional .modal-dialog {
	max-width: 98dvw;
	/* width: 130dvh;
	min-height: 98dvh;
	max-height: 98dvh; */
   width: 80dvh;
   min-height: 60dvh;
   max-height: 60dvh;
   margin: 0;
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) !important;
}

#modalPromocional .modal-body {
	padding: 0;
}

</style>